import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Section from '../components/Section'

const RootIndex = ({ data, location }) => {
  const page = get(data, 'contentfulPageWebPage')
  const sections = get(data, 'contentfulPageWebPage.sections')

  return (
    <Layout location={location}>
      <Hero title={page.headline} sub={page.subTitle} />
      {sections?.map((e) => (
        <Section sectionObj={e} key={e.id} />
      ))}
    </Layout>
  )
}

export default RootIndex

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPageWork(sort: { order: DESC, fields: date }, filter: { featureOnHome: { eq: true } }) {
      edges {
        node {
          date
          categories {
            slug
            title
          }
          portfolioImage {
            gatsbyImageData(aspectRatio: 1.5, width: 1680, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
          id
          client
          slug
        }
      }
    }
    contentfulPageWebPage(slug: { eq: "home" }) {
      id
      headline {
        raw
      }
      subTitle
      sections {
        ... on Node {
          ... on ContentfulWebComponentSectionWithOneImage {
            id
            internal {
              type
            }
            backgroundColor
            imageFirst
            title
            textColor
            content {
              raw
            }
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            button {
              buttonBgColor
              buttonTextColor
              buttonTitle
              buttonUrl
              id
            }
          }
          ... on ContentfulWebComponentSectionWithTwoImages {
            id
            internal {
              type
            }
            title
            imageLeft {
              title
              excerpt
              image {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
              button {
                buttonBgColor
                buttonTextColor
                buttonTitle
                buttonUrl
              }
            }
            imageRight {
              button {
                buttonBgColor
                buttonTextColor
                buttonTitle
                buttonUrl
              }
              excerpt
              title
              image {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
          ... on ContentfulWebComponentSimpleText {
            id
            backgroundColor
            left {
              raw
            }
            right {
              raw
            }
            textColor
            title
            internal {
              type
            }
          }
          ... on ContentfulWebComponentSectionWithMultipleImages {
            id
            internal {
              type
            }
            title
            images {
              image {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
              button {
                buttonBgColor
                buttonTextColor
                buttonTitle
                buttonUrl
              }
              excerpt
              title
              url
            }
          }

          ... on ContentfulWebComponentSectionWithWorks {
            id
            internal {
              type
            }
            title
            works {
              id
              categories {
                title
                slug
              }
              date
              client
              slug
              featureOnHome
              portfolioImage {
                gatsbyImageData(aspectRatio: 1.5, width: 1680, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`
